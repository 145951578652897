import { VUE_APP_BASEURL } from "../../utils/api";
//import router from "../../router";
import axios from "axios";

const state = {
  status: "",
  products: [],
  orderstatus: "",
  orderResponse: [],
  bannerDetails: "",
  footerDetails: "",
  otp: "",
  paymentStatus: "",
  paymentResponse: [],
};

const getters = {
  authStatus: (state) => state.status,
  authStatusresponse: (state) => state.cartResponse,
  getProducts: (state) => state.products,
  orderStatusResponse: (state) => state.orderResponse,
  getOrderStatus: (state) => state.orderstatus,

  paymentStatusResponse: (state) => state.paymentResponse,
  getPaymentStatus: (state) => state.paymentStatus,

  getBannerDetails: (state) => state.bannerDetails,
  getFooterDetails: (state) => state.footerDetails,
  getOTP: (state) => state.otp,
};

const actions = {
  //fetch Products
  async fetchBannerDetails({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/1",
    })
      .then(function (response) {
        //   console.log(response);
        commit("bannerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },
  async fetchFooter({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/1",
    })
      .then(function (response) {
        //  console.log(response);
        commit("footerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },
  //fetch Products
  async fetchProducts({ commit }) {
    commit("authRequest");
    //     console.log(VUE_APP_BASEURL);
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "product-list",
    })
      .then(function (response) {
        // console.log(response);
        commit("productList", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },
  //fetch Products
  async orderConfirm({ commit }, orderData) {
    commit("authRequest");
    console.log(orderData);
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "order",
      data: {
        name: orderData.name,
        email: orderData.email,
        mobileno: orderData.mobileno,
        otp: orderData.otp,
        state: orderData.state,
        deliverycity: orderData.deliverycity,
        address: orderData.address,
        productRate: orderData.productRate,
        productOfferPrice: orderData.productOfferPrice,
        productSalesPrice: orderData.productSalesPrice,
        cartQty: orderData.cartQty,
        orderDetails: orderData.orderDetails,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          // console.log(response);
          commit("authSuccess");
          commit("orderConfirmed", response.data.response.message);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
          console.log(error.response.data.response);
        }
        if (error.response.status == 422) {
          //commit("authError", "AuthenticationError");
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
        }
      });
  },

  //fetch Products
  async fetchWholesaleBannerDetails({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/2",
    })
      .then(function (response) {
        // console.log(response);
        commit("bannerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },

  //fetch Products
  async fetchWholesaleProducts({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "wholesale-product-list",
    })
      .then(function (response) {
        // console.log(response);
        commit("productList", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },
  async wholesaleOrderConfirm({ commit }, orderData) {
    commit("authRequest");
    console.log(orderData);
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "wholesaleorder",
      data: {
        name: orderData.name,
        email: orderData.email,
        mobileno: orderData.mobileno,
        state: orderData.state,
        deliverycity: orderData.deliverycity,
        address: orderData.address,
        productRate: orderData.productRate,
        productOfferPrice: orderData.productOfferPrice,
        productSalesPrice: orderData.productSalesPrice,
        packagePercentage: orderData.packagePercentage,
        packageAmount: orderData.packageAmount,
        totalAmount: orderData.totalAmount,
        cartQty: orderData.cartQty,
        orderDetails: orderData.orderDetails,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          // console.log(response);
          commit("authSuccess");
          commit("orderConfirmed", response.data.response.message);
        }
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
          console.log(error.response.data.response);
        }
        if (error.response.status == 422) {
          //commit("authError", "AuthenticationError");
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
        }
      });
  },
  //fetch Products
  async fetchGiftboxBannerDetails({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/3",
    })
      .then(function (response) {
        // console.log(response);
        commit("bannerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },

  //fetch Products
  async fetchGiftboxProducts({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "giftbox-product-list",
    })
      .then(function (response) {
        // console.log(response);
        commit("productList", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },
  async giftboxOrderConfirm({ commit }, orderData) {
    commit("authRequest");
    console.log(orderData);
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "giftboxorder",
      data: {
        name: orderData.name,
        email: orderData.email,
        mobileno: orderData.mobileno,
        state: orderData.state,
        deliverycity: orderData.deliverycity,
        address: orderData.address,
        productRate: orderData.productRate,
        productOfferPrice: orderData.productOfferPrice,
        productSalesPrice: orderData.productSalesPrice,
        cartQty: orderData.cartQty,
        orderDetails: orderData.orderDetails,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          // console.log(response);
          commit("authSuccess");
          commit("orderConfirmed", response.data.response.message);
        }
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
          console.log(error.response.data.response);
        }
        if (error.response.status == 422) {
          //commit("authError", "AuthenticationError");
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
        }
      });
  },

  //fetch Products
  async fetchFamilypackBannerDetails({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/4",
    })
      .then(function (response) {
        // console.log(response);
        commit("bannerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },

  //fetch Products
  async fetchFamilypackProducts({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "familypack-product-list",
    })
      .then(function (response) {
        // console.log(response);
        commit("productList", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },
  async familypackOrderConfirm({ commit }, orderData) {
    commit("authRequest");
    //console.log(orderData);
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "familypackorder",
      data: {
        name: orderData.name,
        email: orderData.email,
        mobileno: orderData.mobileno,
        state: orderData.state,
        deliverycity: orderData.deliverycity,
        address: orderData.address,
        productRate: orderData.productRate,
        productOfferPrice: orderData.productOfferPrice,
        productSalesPrice: orderData.productSalesPrice,
        cartQty: orderData.cartQty,
        orderDetails: orderData.orderDetails,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          // console.log(response);
          commit("authSuccess");
          commit("orderConfirmed", response.data.response.message);
        }
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
          console.log(error.response.data.response);
        }
        if (error.response.status == 422) {
          //commit("authError", "AuthenticationError");
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
        }
      });
  },
  //otp function
  async otpAction({ commit }, loginData) {
    console.log(loginData);
    commit("authRequest");

    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "otp-request",
      data: {
        mobileno: loginData.mobileno,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("orderOTP", response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          console.log(error.response.data.response);
        }
      });
  },
  //fetch Products
  async fetchPageDetails({ commit }, PageId) {
    console.log(PageId);
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/" + PageId,
    })
      .then(function (response) {
        // console.log(response);
        commit("bannerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },
  //fetch Products
  async fetchDiwaliBannerDetails({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "setting/7",
    })
      .then(function (response) {
        // console.log(response);
        commit("bannerSet", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error);
        }
      });
  },

  //fetch Products
  async fetchDiwaliProducts({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      url: VUE_APP_BASEURL + "diwali-product-list",
    })
      .then(function (response) {
        // console.log(response);
        commit("productList", response);
        commit("authSuccess");
      })
      .catch(function (error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");

            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("apiError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");

          //router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },
  async diwaliOrderConfirm({ commit }, orderData) {
    commit("authRequest");
    //console.log(orderData);
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "diwaliorder",
      data: {
        name: orderData.name,
        email: orderData.email,
        mobileno: orderData.mobileno,
        otp: orderData.otp,
        state: orderData.state,
        deliverycity: orderData.deliverycity,
        address: orderData.address,
        productRate: orderData.productRate,
        productOfferPrice: orderData.productOfferPrice,
        productSalesPrice: orderData.productSalesPrice,
        cartQty: orderData.cartQty,
        orderDetails: orderData.orderDetails,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          // console.log(response);
          commit("authSuccess");
          commit("orderConfirmed", response.data.response.message);
        }
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
          console.log(error.response.data.response);
        }
        if (error.response.status == 422) {
          //commit("authError", "AuthenticationError");
          commit("authError", error.response.data.response);
          commit("orderConfirmedError", error.response.data.response);
        }
      });
  },

  async retailPaymentConfirm({ commit }, orderData) {
    commit("authRequest");
    //console.log(orderData);
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "screenshot-upload",
      data: {
        ordertype: orderData.ordertype,
        orderno: orderData.orderno,
        mobileno: orderData.mobileno,
        screenshot: orderData.screenshot,
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          // console.log(response.data.response);
          commit("authSuccess");
          commit("paymentConfirmed", response.data.message);
        }
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          commit("paymentConfirmedError", error.response.data.response);
          console.log(error.response.data.response);
        }
        if (error.response.status == 422) {
          //commit("authError", "AuthenticationError");
          commit("authError", error.response.data.response);
          commit("paymentConfirmedError", error.response.data.response);
        }
      });
  },
};

const mutations = {
  authRequest: (state) => {
    state.status = "loading";
    state.orderstatus = "";
    state.cartStatus = "";
  },
  authSuccess: (state) => {
    state.status = "success";
  },
  authEmpty: (state) => {
    state.status = "";
  },
  authError: (state, resp) => {
    console.log(resp);
    state.status = "error";
    state.hasLoadedOnce = true;
    state.orderResponse = resp;
  },
  productList: (state, resp) => {
    state.status = "";
    state.products = resp.data.data;
  },
  orderConfirmed: (state, resp) => {
    state.orderstatus = "success";
    state.status = "success";
    state.orderResponse = resp;
  },
  orderConfirmedError: (state, resp) => {
    state.orderstatus = "error";
    state.status = "error";
    state.orderResponse = resp;
  },
  paymentConfirmed: (state, resp) => {
    state.paymentStatus = "success";
    state.status = "success";
    state.paymentResponse = resp;
  },
  paymentConfirmedError: (state, resp) => {
    state.paymentStatus = "error";
    state.status = "error";
    state.paymentResponse = resp;
  },
  bannerSet: (state, resp) => {
    state.bannerDetails = resp.data.response;
  },
  footerSet: (state, resp) => {
    state.footerDetails = resp.data.response;
  },
  orderOTP: (state, resp) => {
    console.log(resp);
    state.otp = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
