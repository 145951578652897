<template>
    <div class="home">
      <div
        class="primary d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar"
      >
        <v-row justify="space-around">
          <v-col cols="12" sm="12" md="1" lg="1">
            <span class="white--text"></span>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3" class="mt-2 mb-2">
            <span class="white--text"
              ><span class="white--text"
                ><v-icon color="#FFF" small>mdi-phone</v-icon> 81100 20091</span
              ></span
            >
          </v-col>
          <v-col cols="12" sm="12" md="2" lg="2">
            <span class="white--text"></span>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="2">
            <span class="white--text"></span>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3" class="mt-2 mb-2">
            <span class="white--text"
              ><v-icon color="#FFF" small>mdi-email</v-icon>
              kannancrackers2015@gmail.com</span
            >
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1">
            <span class="white--text"></span>
          </v-col>
        </v-row>
      </div>
  
      <v-app-bar color="white" fixed height="125px" class="mt-md-8 mt-lg-8">
        <v-container>
          <v-row
            justify="space-around"
            id="container"
            ref="container"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
              lg="6"
              large
              class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
            >
              <v-breadcrumbs :items="menus" divider=" ">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item :href="item.href">
                    <v-btn small color="secondary" class="mb-2">{{
                      item.text
                    }}</v-btn>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </v-col>
            <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
              <!-- <v-breadcrumbs :items="menus"></v-breadcrumbs> -->
              <img alt="Vue logo" src="../assets/logo.png" height="60" contain />
              <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>        
            </div>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
            >
              <img alt="Vue logo" src="../assets/logo.png" height="60" contain />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="3"
              class="d-flex justify-space-between flex-row text-wrap"
            >
              <v-btn icon v-if="cartQty" class="mb-8" @click="popupCheckout">
                <v-badge
                  color="black"
                  :content="cartQty"
                  overlap
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon color="black">mdi-cart-outline</v-icon>
                </v-badge>
              </v-btn>
              <v-btn icon v-if="cartQty == ''" class="mb-8">
                <v-badge
                  color="black"
                  content="0"
                  overlap
                  offset-x="10"
                  offset-y="10"
                  bordered
                >
                  <v-icon color="black">mdi-cart-outline</v-icon>
                </v-badge>
              </v-btn>
              <v-btn text class="mb-8">
                <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                  NET TOTAL :
                </div>
                ₹ {{ productSalesPrice }}
              </v-btn>
              <v-btn outlined color="primary"  @click="contactDialog = true"
                ><v-icon color="primary" small class="mr-2">mdi-phone</v-icon
                >Contact</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
  
 
  <!--Navigation Drawer-->
  <v-navigation-drawer
        v-model="drawer" absolute  right temporary  >
        <v-list nav  dense  >
          <v-list-item-group
            v-model="group"
            active-class="primary white--text"
          >
          <div v-for="item in menus" :key="item.text">
            <v-list-item :href="item.href">
              <v-list-item-title >{{ item.text }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </div>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
        <!--Navigation Drawer-->
      <v-container>
        <div class="productdiv" ref="productdiv1"></div>
        <v-card class="mt-1 mx-auto" height="150" elevation="0">
          <div
            class="
              d-flex
              flex-column
              fill-height
              justify-center
              align-center
              text-black
            "
          >
            <h1 class="primary--text">
              {{ banner.title }}
            </h1>
          </div>
        </v-card>
  
        <v-card class="mx-auto" elevation="0">
          <div
            class="
              d-flex
              flex-column
              fill-height
              justify-center
              align-center
              text-black
            "
          >
            <div v-html="banner.details" class="text-justify"></div>

            

          </div>
        </v-card>
      </v-container>
  

    
      <loading v-if="loading" />
  
      <!--footer Component-->
      <div>
        <footerCommon />
      </div>
  
      <!--footer Component-->
  
      
    </div>
  </template>
  
  <style scoped>
  .top-bar {
    position: fixed;
    width: 100%;
    z-index: 111;
  }
  
  .v-application p {
    margin-bottom: 0px;
  }
  .productdiv {
  margin-top: 165px !important;
}

.banner-text {
  line-height: 50px;
}

.product-cart-div {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.product-cart-card {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.v-navigation-drawer{
  position: fixed  !important;
}
  </style>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
 
  export default {
    name: "Faq",
    data: () => ({
        productRate: "0.00",
    productOfferPrice: "0.00",
    productSalesPrice: "0.00",
    cartQty: 0,
        menus: [
      {
        text: "Retail Price",
        disabled: false,
        href: "/",
      },
      {
        text: "Family pack",
        disabled: false,
        href: "familypack",
      },
      {
        text: "Wholesale case",
        disabled: false,
        href: "wholesale",
      },
      {
        text: "Whole sale",
        disabled: false,
        href: "diwali",
      },
      {
        text: "Gift box",
        disabled: false,
        href: "giftbox",
      },
      {
        text: "About Us",
        disabled: false,
        href: "aboutus",
      },
      {
        text: "Contact Us",
        disabled: false,
        href: "contactus",
      },
      
    ],
    drawer: false,
    group: null,
    }),
    computed: {
      ...mapGetters([
        "authStatus",
        "authStatusError",
        "authStatusresponse",
        "getBannerDetails",
      ]),
      
      loading: function () {
        return this.authStatus === "loading";
      },
      banner: function () {
      return this.getBannerDetails;
    },
     
    },
    created() {
      this.fetchPageDetails("8");

    },
    methods: {
      ...mapActions([
        "fetchPageDetails",
      ]),
      
    },
  };
  </script>
  