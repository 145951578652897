<template>
  <v-footer dark padless>
    <v-card flat tile class="white--text text-center footer-card">
      <v-card-text class="white--text pt-0">
        <v-row justify="space-around" id="container" ref="container">
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address1"></div>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address2"></div>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address3"></div>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address4"></div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode1"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode1
              "
              height="250"
              contain
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode2"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode2
              "
              height="250"
              contain
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode3"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode3
              "
              height="250"
              contain
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode4"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode4
              "
              height="250"
              contain
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pa-0 white--text">
        <v-breadcrumbs
      :items="menus"
      divider="-"
      class="justify-center white--text breadcrum-color" color="white" ></v-breadcrumbs>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — <strong>Kannancrackers</strong> —
        <a @click="websiteOpen" class="font-weight-bold white--text">Developed By Smartxpose Technologies</a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style>
.footer-card {
  width: 100%;
}
.breadcrum-color a {
  color: #fff !important;
}
</style>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

import { VUE_WEB_BASEURL } from "../utils/api";

export default {
  name: "FooterCommon",
  data: () => ({
    imageUrl: "",
    menus: [
      {
        text: "FAQ",
        disabled: false,
        href: "faq",
      },
      {
        text: "Privacy Policy",
        disabled: false,
        href: "privacypolicy",
      },
      {
        text: "Terms And Condition",
        disabled: false,
        href: "termsandcondition",
      },
    ],
  }),
  computed: {
    ...mapGetters(["getFooterDetails"]),
  },
  created() {
    this.fetchFooter();
    this.imageUrl = VUE_WEB_BASEURL;
  },
  methods: {
    ...mapActions(["fetchFooter"]),
    websiteOpen() {
      window.open("https://smartxpose.com/", "_blank");
    },
  },
};
</script>
